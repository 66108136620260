import W0001V0001 from './W0001V0001.vue';
import W0003V0001 from './W0003V0001.vue';
import W0004V0001 from './W0004V0001.vue';
import W0005V0001 from './W0005V0001.vue';
import W0006V0001 from './W0006V0001.vue';
import W0007V0001 from './W0007V0001.vue';
import W0009V0001 from './W0009V0001.vue';
import W0010V0001 from './W0010V0001.vue';
import W0011V0001 from './W0011V0001.vue';
import W0012V0001 from './W0012V0001.vue';
import W0013V0002 from './W0013V0002.vue';
import W0014V0001 from './W0014V0001.vue';
import W0016V0001 from './W0016V0001.vue';
import W0017V0001 from './W0017V0001.vue';
import W0018V0001 from './W0018V0001.vue';
import W0019V0001 from './W0019V0001.vue';
import W0020V0001 from './W0020V0001.vue';
import W0021V0001 from './W0021V0001.vue';
import W0022V0001 from './W0022V0001.vue';
import W0023V0001 from './W0023V0001.vue';
import W0024V0001 from './W0024V0001.vue';
import W0025V0002 from './W0025V0002.vue';
import W0026V0001 from './W0026V0001.vue';
import W0027V0001 from './W0027V0001.vue';
import W0028V0001 from './W0028V0001.vue';
import W0029V0001 from './W0029V0001.vue';
import W0030V0001 from './W0030V0001.vue';
import W0031V0001 from './W0031V0001.vue';
import W0032V0001 from './W0032V0001.vue';
import W0033V0001 from './W0033V0001.vue';
import W0034V0001 from './W0034V0001.vue';
import W0035V0001 from './W0035V0001.vue';
import W0036V0001 from './W0036V0001.vue';
import W0037V0001 from './W0037V0001.vue';
import W0040V0001 from './W0040V0001.vue';
import W0041V0001 from './W0041V0001.vue';
import W0042V0001 from './W0042V0001.vue';
import W0044V0001 from './W0044V0001.vue';
import W0045V0001 from './W0045V0001.vue';
import W0046V0001 from './W0046V0001.vue';
import W0047V0001 from './W0047V0001.vue';
import W0048V0001 from './W0048V0001.vue';
import W0049V0001 from './W0049V0001.vue';
import W0050V0001 from './W0050V0001.vue';
import W0051V0001 from './W0051V0001.vue';
import W0052V0001 from './W0052V0001.vue';
import W0053V0001 from './W0053V0001.vue';
import W0054V0001 from './W0054V0001.vue';
import W0056V0001 from './W0056V0001.vue';
import W0057V0001 from './W0057V0001.vue';
import W0058V0001 from './W0058V0001.vue';
import W0059V0001 from './W0059V0001.vue';
import W0060V0003 from './W0060V0003.vue';
import W0062V0001 from './W0062V0001.vue';
import W0063V0001 from './W0063V0001.vue';
import W0064V0001 from './W0064V0001.vue';
import W0065V0001 from './W0065V0001.vue';
import W0066V0001 from './W0066V0001.vue';
import W0067V0001 from './W0067V0001.vue';
import W0068V0001 from './W0068V0001.vue';
import W0069V0001 from './W0069V0001.vue';
import W0070V0001 from './W0070V0001.vue';
import W0071V0001 from './W0071V0001.vue';
import W0072V0001 from './W0072V0001.vue';
import W0073V0001 from './W0073V0001.vue';
import W0074V0001 from './W0074V0001.vue';
import W0075V0001 from './W0075V0001.vue';
import W0076V0001 from './W0076V0001.vue';
import W0077V0001 from './W0077V0001.vue';
import W0080V0001 from './W0080V0001.vue';
import W0081V0001 from './W0081V0001.vue';
import W0082V0001 from './W0082V0001.vue';
import W0083V0001 from './W0083V0001.vue';
import W0084V0001 from './W0084V0001.vue';
import W0085V0001 from './W0085V0001.vue';
import W0086V0001 from './W0086V0001.vue';
import W0087V0001 from './W0087V0001.vue';
import W0088V0001 from './W0088V0001.vue';
import W0089V0001 from './W0089V0001.vue';
import W0090V0001 from './W0090V0001.vue';
import W0091V0001 from './W0091V0001.vue';
import W0092V0001 from './W0092V0001.vue';
import W0093V0001 from './W0093V0001.vue';
import W0094V0001 from './W0094V0001.vue';
import W0096V0001 from './W0096V0001.vue';
import W0097V0001 from './W0097V0001.vue';
import W0100V0001 from './W0100V0001.vue';
import W0101V0001 from './W0101V0001.vue';
import W0102V0001 from './W0102V0001.vue';
import W0103V0001 from './W0103V0001.vue';
import W0104V0001 from './W0104V0001.vue';
import W0106V0001 from './W0106V0001.vue';
import W0109V0001 from './W0109V0001.vue';
import W0111V0001 from './W0111V0001.vue';
import W0112V0001 from './W0112V0001.vue';
import W0114V0001 from './W0114V0001.vue';
import W0115V0001 from './W0115V0001.vue';
import W0116V0001 from './W0116V0001.vue';
import W0118V0001 from './W0118V0001.vue';
import W0119V0001 from './W0119V0001.vue';
import W0120V0001 from './W0120V0001.vue';
import W0126V0001 from './W0126V0001.vue';
import W0127V0001 from './W0127V0001.vue';
import W0129V0001 from './W0129V0001.vue';
import W0130V0001 from "./W0130V0001.vue";
import W0132V0001 from "./W0132V0001.vue";
import W0134V0001 from "./W0134V0001.vue";
import W0136V0001 from "./W0136V0001.vue";
import W0137V0001 from "./W0137V0001.vue";
import W0138V0001 from "./W0138V0001.vue";
import W0141V0001 from "./W0141V0001.vue";
import W10V1 from './W10V1.vue';
import W11V1 from './W11V1.vue';
import W12V1 from './W12V1.vue';
import W1V1 from './W1V1.vue';
import W2V1 from './W2V1.vue';
import W4V1 from './W4V1.vue';
import W5V1 from './W5V1.vue';
import W6V1 from './W6V1.vue';
import W7V1 from './W7V1.vue';
import W8V1 from './W8V1.vue';
import W9V1 from './W9V1.vue';
import W13V1 from './W13V1.vue';
import W14V1 from './widgetcomponents/W14V1/Widget.vue';

export const widgets = {
W0001V0001,
W0003V0001,
W0004V0001,
W0005V0001,
W0006V0001,
W0007V0001,
W0009V0001,
W0010V0001,
W0011V0001,
W0012V0001,
W0013V0002,
W0014V0001,
W0016V0001,
W0017V0001,
W0018V0001,
W0019V0001,
W0020V0001,
W0021V0001,
W0022V0001,
W0023V0001,
W0024V0001,
W0025V0002,
W0026V0001,
W0027V0001,
W0028V0001,
W0029V0001,
W0030V0001,
W0031V0001,
W0032V0001,
W0033V0001,
W0034V0001,
W0035V0001,
W0036V0001,
W0037V0001,
W0040V0001,
W0041V0001,
W0042V0001,
W0044V0001,
W0045V0001,
W0046V0001,
W0047V0001,
W0048V0001,
W0049V0001,
W0050V0001,
W0051V0001,
W0052V0001,
W0053V0001,
W0054V0001,
W0056V0001,
W0057V0001,
W0058V0001,
W0059V0001,
W0060V0003,
W0062V0001,
W0063V0001,
W0064V0001,
W0065V0001,
W0066V0001,
W0067V0001,
W0068V0001,
W0069V0001,
W0070V0001,
W0071V0001,
W0072V0001,
W0073V0001,
W0074V0001,
W0075V0001,
W0076V0001,
W0077V0001,
W0080V0001,
W0081V0001,
W0082V0001,
W0083V0001,
W0084V0001,
W0085V0001,
W0086V0001,
W0087V0001,
W0088V0001,
W0089V0001,
W0090V0001,
W0091V0001,
W0092V0001,
W0093V0001,
W0094V0001,
W0096V0001,
W0097V0001,
W0100V0001,
W0101V0001,
W0102V0001,
W0103V0001,
W0104V0001,
W0106V0001,
W0109V0001,
W0111V0001,
W0112V0001,
W0114V0001,
W0115V0001,
W0116V0001,
W0118V0001,
W0119V0001,
W0120V0001,
W0126V0001,
W0127V0001,
W0129V0001,
W0130V0001,
W0132V0001,
W0134V0001,
W0136V0001,
W0137V0001,
W0138V0001,
W0141V0001,
W10V1,
W11V1,
W12V1,
W1V1,
W2V1,
W4V1,
W5V1,
W6V1,
W7V1,
W8V1,
W9V1,
W13V1,
W14V1
}
